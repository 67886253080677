/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import Badge from 'Badge';
import Button from 'Button';
import CartItemList from 'Cart/CartItemList';
import Fade from 'Fade';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdDone } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useTrackingQueue from '../../../hooks/useTrackingQueue';
import { hideModalCart } from '../../../redux';
import beginCheckout from '../../../services/beginCheckout';
import generateHrefLink from '../../../services/page/generateLinkHref';
import styles from './cart-modal.module.scss';

function CartModal() {
   const [user, setUser] = useState(null);
   const dispatch = useDispatch();
   const router = useRouter();
   const visible = useSelector((state) => state.cart.showModal);
   const cart = useSelector((state) => state.cart);
   const modalRef = useRef(null);
   const { executeEvent } = useTrackingQueue();

   useEffect(() => {
      setUser(localStorage.getItem('user'));
   }, []);

   // detectar si se hizo click afuera del modal
   // y lo ocultamos
   useOutsideClick(modalRef, () => {
      if (visible) {
         dispatch(hideModalCart());
      }
   });

   const handleClickButon = async (type) => {
      dispatch(hideModalCart());
      if (type === 'cart') {
         executeEvent('viewCart');
         router.push('/cart');
      } else {
         executeEvent('beginCheckout');
         await beginCheckout(cart.state, dispatch);
         router.push('/cart');
         // router.push(user ? '/checkout/confirm-data' : '/mail-login');
      }
   };

   return (
      <Fade show={visible} className={styles.wrapper}>
         <Fade show={visible} className={styles.background} />

         <div ref={modalRef} className={styles.modal}>
            <span className={styles.message}>
               <Badge icon={<MdDone />} text>
                  ¡Agregado al carrito!
               </Badge>
            </span>
            <div className={styles.cartItemList}>
               <CartItemList />
            </div>
            <div className={styles.buttons}>
               {/* <div className={styles.cartButton}>
                  <Button
                     label="Ir a carrito"
                     variant="secondary"
                     onClick={() => {
                        handleClickButon('cart');
                     }}
                  />
               </div> */}

               <div className={styles.checkoutButton}>
                  {cart.line_items.length > 0 && (
                     <Button
                        id="Proceder_a_la_compra_productDetail"
                        label="Proceder a la compra"
                        variant="primary"
                        onClick={() => handleClickButon('checkout')}
                     />
                  )}
                  {cart.line_items.length === 0 && (
                     <Link href={generateHrefLink('productos', router)}>
                        <a>
                           <Button label="Continuar comprando" className="checkoutButton" />
                        </a>
                     </Link>
                  )}
               </div>
            </div>
         </div>
      </Fade>
   );
}

export default CartModal;
