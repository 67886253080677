import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useNavbar(landing = false, index = false) {
   const reduxNavbar = useSelector((state) => state.navbar);
   const reduxLanding = useSelector((state) => state.landing);
   const site = useSelector((state) => state.site);
   const [navbar, setNavbar] = useState({
      dropdowns: [],
      links: [],
   });

   useEffect(() => {
      if (landing) {
         setNavbar(reduxLanding.navbar);
      } else {
         setNavbar(reduxNavbar);
      }
   }, []);

   return navbar;
}
