import React, { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import PropTypes from 'prop-types';
import { MdKeyboardArrowRight } from 'react-icons/md';
import CategoryList from './CategoryList';
import styles from './category.module.scss';

function Category(props) {
   const { name, items, url, selected, single } = props;
   const [open, setOpen] = useState(!!single);
   const LIMIT_CATEGORY = Math.floor(items.length / 2);
   const isMobile = useMediaQuery('(max-width: 768px)');
   const handleClick = () => {
      setOpen(!open);
   };

   return (
      <div onClick={handleClick} onKeyPress={handleClick} role="button" className={styles.wrapper}>
         <h2 className={styles.title}>
            {name} <MdKeyboardArrowRight className={styles.icon} />
         </h2>

         {!single ? (
            <CategoryList
               items={items}
               url={url}
               open={open}
               onClose={() => setOpen(false)}
               selected={selected}
               single={single}
            />
         ) : (
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '700px',
               }}
            >
               {isMobile && (
                  <CategoryList
                     items={items}
                     url={url}
                     open={open}
                     onClose={() => setOpen(false)}
                     selected={selected}
                     single={single}
                  />
               )}
               {!isMobile && (
                  <CategoryList
                     items={items.slice(0, LIMIT_CATEGORY)}
                     url={url}
                     open={open}
                     onClose={() => setOpen(false)}
                     selected={selected}
                     single={single}
                  />
               )}
               {!isMobile && items.length > LIMIT_CATEGORY && (
                  <CategoryList
                     items={items.slice(LIMIT_CATEGORY, items.length)}
                     url={url}
                     open={open}
                     onClose={() => setOpen(false)}
                     selected={selected}
                     single={single}
                     isLast
                  />
               )}
            </div>
         )}
      </div>
   );
}

Category.propTypes = {
   name: PropTypes.string.isRequired,
   items: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string,
         url: PropTypes.string,
      }),
   ).isRequired,
};

export default Category;
