import api from '../../api';
import { advanceToNextSuccess, fetchCart } from '../../redux';

export default async function beginCheckout(state, dispatch) {
   if (state === 'cart') {
      const next = await api.checkout.advanceToNext();

      if (next.success) {
         localStorage.setItem('redirectTo', '/checkout/shipping-method');
         dispatch(advanceToNextSuccess(next.data.attributes));
      } else {
         dispatch(fetchCart());
         localStorage.setItem('redirectTo', '/cart');
      }
   } else {
      localStorage.setItem('redirectTo', '/checkout/shipping-method');
   }
}
