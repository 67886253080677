/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Link from 'next/link';
import { MdAccountCircle, MdShoppingBasket, MdExitToApp } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { deleteCart, logout, resetCheckoutStates } from '../../../../../redux';
import styles from './dropdown.module.scss';

function Dropdown(props) {
   const { user } = props;
   const dispatch = useDispatch();

   const renderItems = () => {
      return (
         <ul className={styles.items}>
            <h1 className={styles.title}>
               ¡Hola <span>{user}</span>!
            </h1>
            <hr className={styles.line} />

            <li className={styles.item}>
               <Link href="/account">
                  <a className="link-style">
                     <MdAccountCircle size={20} className={styles.icon} />
                     Mi cuenta
                  </a>
               </Link>
            </li>

            <li className={styles.item}>
               <Link href="/orders">
                  <a className="link-style">
                     <MdShoppingBasket size={20} className={styles.icon} />
                     Mis pedidos
                  </a>
               </Link>
            </li>

            <li className={styles.item}>
               <a
                  onClick={() => [
                     dispatch(logout()),
                     dispatch(resetCheckoutStates(false)),
                     dispatch(deleteCart()),
                  ]}
                  className="link-style"
               >
                  <MdExitToApp size={20} className={styles.icon} />
                  Cerrar sesión
               </a>
            </li>
         </ul>
      );
   };

   return <div className={styles.container}>{renderItems()}</div>;
}

export default Dropdown;
