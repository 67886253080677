import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { MdAccountCircle, MdArrowDropDown } from 'react-icons/md';
import { useSelector } from 'react-redux';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Dropdown from './Dropdown/index';
import styles from './userItem.module.scss';

function UserItem() {
   const auth = useSelector((state) => state.auth);
   const ref = useRef(null);
   const [open, setOpen] = useState(false);

   useOutsideClick(ref, () => {
      if (open) {
         setOpen(false);
      }
   });

   const classContent = cn({
      [styles.content]: true,
      [styles['content--active']]: open,
   });

   const classIcon = cn({
      [styles.icon]: true,
      [styles['icon--active']]: open,
   });

   const handleClick = () => {
      setOpen(!open);
   };
   return (
      <div ref={ref} className={styles.wrapper}>
            <div className={styles.loginLink}>
               {' '}
               <div
                  className={classContent}
                  onClick={handleClick}
                  onKeyPress={handleClick}
                  role="button"
               >
                  <MdAccountCircle size={22} />
                  <span className={styles.label}>{auth.user.name}</span>
                  <MdArrowDropDown className={classIcon} />
               </div>
            </div>

            {open && <Dropdown user={auth.user.name} />}
      </div>
   );
}

export default UserItem;
