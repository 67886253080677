import { useEffect } from 'react';

// hook para descargar la lib de chat wcentrix
// se aplica un retardo de X segundos para evitar cargarlo al inicio de la app
export default function useWcentrixChat() {
   useEffect(() => {
      setTimeout(() => {
         // creamos una etiqueta <script> y ponemos el src del js
         // para que comience a descargar
         const url = `https://api.wcx.cloud/widget/?id=${process.env.NEXT_PUBLIC_WEBCENTRIX_ID}`;
         const s = document.createElement('script');
         s.setAttribute('src', url);
         document.body.appendChild(s);
      }, 3500);
   }, []);
}
