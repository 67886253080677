import React from 'react';
import Placeholder from 'Placeholder';
import styles from '../NavBarItem/navbaritem.module.scss';

function NavBarSkeleton() {
   return Array(5)
      .fill()
      .map(() => (
         <li className={styles.wrapper}>
            <div className={styles.content}>
               <Placeholder height="20" className={styles.placeholder}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
               </Placeholder>
            </div>
         </li>
      ));
}

export default NavBarSkeleton;
