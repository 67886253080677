import React from 'react';
import PropTypes from 'prop-types';
import Category from '../Category';
import ImageLink from '../ImageLink';
import styles from './dropdown.module.scss';

function Dropdown(props) {
   const { image, name, url, categories, selected } = props;
   const renderCategories = () => {
      return categories.length > 0 ? (
         categories.map((cat) => (
            <Category
               key={cat.name}
               name={cat.name}
               items={cat.items}
               url={cat.url}
               selected={selected}
               single={categories.length === 1}
            />
         ))
      ) : (
         <> </>
      );
   };

   return (
      <nav className={styles.wrapper}>
         {renderCategories()}
         {image ? (
            <div className={styles.linksImageLink}>
               <div className={styles.links}>
                  {/* TODO: apuntar a slug con el orden que corresponda (Ofertas o Lanzamientos) */}
               </div>
               <ImageLink name={name} url={url} image={image} selected={selected} />
            </div>
         ) : null}
      </nav>
   );
}

Dropdown.propTypes = {
   name: PropTypes.string.isRequired,
   // eslint-disable-next-line react/require-default-props
   image: PropTypes.shape({
      url: PropTypes.string,
      ext: PropTypes.string,
      blurhash: PropTypes.string,
   }),
   url: PropTypes.string.isRequired,
   categories: PropTypes.instanceOf(Array).isRequired,
};

export default Dropdown;
