/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../../redux';
import { destroyCookie } from '../../../services/cookies/index';
import generateHrefLink from '../../../services/page/generateLinkHref';
import styles from './header-lite.module.scss';

function HeaderLite() {
   const dispatch = useDispatch();
   // TODO: validar que muestre los logos correctos
   const router = useRouter();
   const checkout = useSelector((state) => state.checkout);
   const site = useSelector((state) => state.site);
   const { guestCheckout, noLogin, noLoginPrevious } = checkout;

   const onClickHandler = () => {
      // dispatch(setTheme(proc));
      switch (true) {
         case guestCheckout:
         case noLogin:
         case noLoginPrevious:
            destroyCookie('token');
            destroyCookie('spsToken');
            destroyCookie('refreshToken');
            break;
         default:
            break;
      }
   };
   return (
      <header className={styles.wrapper}>
         {site.main?.name && (
            <Link href={generateHrefLink('', router)}>
               <a className={styles.image} onClick={onClickHandler}>
                  <Image
                     src={`/assets/logos/${site.main.storeId}.svg`}
                     alt={`${site.main.name} | ${site.main.slogan}`}
                     className={styles.image}
                     width={110}
                     height={30}
                     priority
                  />
               </a>
            </Link>
         )}
         {/* {site.micrositios?.length > 0 &&
            site.micrositios?.map((s) => (
               <Link href={generateHrefLink('', router)} key={s.name}>
                  <a className={styles.image} onClick={onClickHandler}>
                     <Image
                        src={`/assets/logos/${s.storeId}.svg`}
                        alt={`${s.name} | ${s.slogan}`}
                        className={styles.image}
                        width={70}
                        height={30}
                        priority
                     />
                  </a>
               </Link>
            ))} */}
      </header>
   );
}

export default HeaderLite;
