import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import generateHrefLink from '../../../../../services/page/generateLinkHref';
import styles from './category-list.module.scss';

function CategoryList(props) {
   const { items, url, onClose, open, selected, single, isLast } = props;
   const [isOpen, setOpen] = useState(open);

   useEffect(() => {
      setOpen(props.open);
   }, [open]);

   const classWrapper = cn({
      [styles.wrapper]: true,
      [styles['wrapper--active']]: isOpen,
      [styles.single]: single,
   });
   const handleClickBack = () => {
      onClose();
   };

   const renderItems = (items) => {
      return items.map((x) => {
         if (
            !x.url.includes('adidas') &&
            !x.url.includes('nino') &&
            !x.url.includes('mujer') &&
            !x.url.includes('hombre') &&
            !x.url.includes('productos')
         ) {
            x.url = `productos${x.url}`;
         }
         if (x.url[0] === '/') x.url = x.url.slice(1);
         return (
            <li className={styles.item} key={x.name}>
               <Link href={generateHrefLink(x.url, useRouter())}>
                  <a onClick={selected} onKeyPress={selected} role="link">
                     {x.name}
                  </a>
               </Link>
            </li>
         );
      });
   };

   return (
      <ul className={classWrapper}>
         {!single && (
            <span
               className={styles.back}
               onClick={handleClickBack}
               onKeyPress={handleClickBack}
               role="button"
            >
               <MdKeyboardArrowLeft className={styles.icon} /> Volver
            </span>
         )}
         {renderItems(items)}
         {isLast || !single ? (
            <li className={styles.seeAll}>
               <Link href={generateHrefLink(url, useRouter())}>
                  <a onClick={selected} onKeyPress={selected} role="link">
                     Ver todo
                  </a>
               </Link>
            </li>
         ) : null}
      </ul>
   );
}

export default CategoryList;
