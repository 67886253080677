/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Input from 'Form/Input';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import formatTextQuantity from '../../../../services/formatTextQuantity';
import styles from './dropdown.module.scss';

const Form = dynamic(() => import('Form'));

function Dropdown({ countOnHand, quantity, onSelect }) {
   const [showInput, setShowInput] = useState(false);

   const handleClickItem = (qty) => {
      setShowInput(false);
      onSelect(Number(qty));
   };

   const handleSubmitInput = ({ quantity }) => {
      setShowInput(false);
      onSelect(Number(quantity));
   };

   const renderItems = () => {
      const array = [];

      for (let x = 1; x <= countOnHand; x += 1) {
         if (x <= 6) {
            const classItem = cn({
               [styles.item]: true,
               [styles['item--active']]: x === quantity,
            });

            array.push(
               <li key={x} className={classItem}>
                  <button onClick={() => handleClickItem(x)}>{formatTextQuantity(x)}</button>
               </li>,
            );
         }
      }

      return array;
   };

   const renderInput = () => {
      if (countOnHand > 6) {
         return (
            <li className={styles.item}>
               <button onClick={() => setShowInput(true)}>Más de 6 unidades</button>

               {showInput && (
                  <Form onSubmit={handleSubmitInput} defaultValues={{ quantity: 1 }}>
                     <Input
                        name="quantity"
                        type="number"
                        label="Cantidad:"
                        className={styles.input}
                        classLabel={styles.label}
                        classError={styles.error}
                        validation={{
                           max: {
                              value: countOnHand,
                              message: 'Sin stock',
                           },
                           min: {
                              value: 7,
                              message: 'Ingresa un numero mayor a 6',
                           },
                        }}
                        required
                     />
                     <button type="submit">Agregar</button>
                  </Form>
               )}
            </li>
         );
      }

      return null;
   };

   return (
      <ul className={styles.container}>
         {renderItems()}
         {renderInput()}
      </ul>
   );
}

Dropdown.propTypes = {
   countOnHand: PropTypes.number,
   quantity: PropTypes.number.isRequired,
   onSelect: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
   countOnHand: null,
};

export default Dropdown;
