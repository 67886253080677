import React, { useCallback } from 'react';
import cn from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useNavbar from '../../../hooks/useNavbar';
import NavBarItem from './NavBarItem';
import NavBarLink from './NavBarLink';
import NavBarSkeleton from './NavBarSkeleton';
import styles from './navbar.module.scss';

function NavBar(props) {
   const { className, index, landing } = props;
   const { isFallback } = useRouter();
   const navbar = useNavbar(landing, index);

   const navbarClass = cn({
      [styles.wrapper]: true,
      [styles['wrapper--over-content']]: index,
      [className]: !!className,
   });

   const renderNavBarDropdown = useCallback(() => {
      return navbar.dropdowns?.map((x, i) => <NavBarItem key={x.name} index={i} item={x} />);
   }, [navbar]);

   const renderNavBarLink = useCallback(() => {
      return navbar.links?.map((x, i) => <NavBarLink key={x.name} index={i} item={x} />);
   }, [navbar]);
   return (
      <>
         {navbar.dropdowns.length || navbar.links.length ? (
            <div className={navbarClass}>
               <ul>
                  {isFallback && <NavBarSkeleton />}

                  {landing && navbar.image?.url && (
                     <Image src={navbar.image.url} width={80} height={40} objectFit="cover" />
                  )}

                  {!isFallback && (
                     <>
                        {renderNavBarDropdown()}
                        {renderNavBarLink()}
                     </>
                  )}
               </ul>
            </div>
         ) : null}
      </>
   );
}

NavBar.propTypes = {};

export default NavBar;
