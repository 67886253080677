import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import FooterContent from './FooterContent';
import Newsletter from './Newsletter';
import styles from './footer.module.scss';
import ProweredByVaypol from '../../../components/PoweredByVaypol';

const FooterBottom = dynamic(() => import(/* webpackChunkName: "footer" */ './FooterBottom'));

function Footer({ index }) {
   const router = useRouter();
   const site = useSelector((state) => state.site);

   const [viewRef, inView] = useInView({
      threshold: 0,
      rootMargin: '400px',
      triggerOnce: true,
   });

   return (
      <footer ref={viewRef}>
         {inView && (
            <>
               <section className={styles.wrapperTop}>
                  {!(
                     router.pathname.includes('account') ||
                     router.pathname.includes('forgot-password')
                  ) &&
                     site.main.newsletter !== 'false' && <Newsletter />}
               </section>
               <section className={styles.wrapperMedium}>
                  <div className={styles.content}>
                     <FooterContent index={index} />
                  </div>
               </section>
               <ProweredByVaypol />
               <section className={styles.wrapperBottom}>
                  <div className={styles.content}>
                     <FooterBottom />
                  </div>
               </section>
            </>
         )}
      </footer>
   );
}

export default Footer;
