import api from '../../api';

export default async function verifyLanding(value, storeId) {
   value = value.toLowerCase();
   value = value.replace(' ', '-');

   const landing = await api.landings.get(value, storeId);

   if (!landing.success) {
      return null;
   }

   return { value };
}
