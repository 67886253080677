import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import stringWithoutSpaces from '../../services/PropTypes/stringWithoutSpaces';
import styles from './badge.module.scss';

function Badge(props) {
   const {
      color,
      backgroundColorLeft,
      backgroundColorRight,
      uppercase,
      featureSpecial,
      text,
      icon,
      children,
      className,
      padding,
   } = props;

   const classBadge = cn({
      [styles.wrapper]: true,
      [styles[className]]: className !== null,
      [styles['wrapper--uppercase']]: uppercase,
      [styles['wrapper--featureSpecial']]: featureSpecial,
      [styles['wrapper--text']]: text,
   });

   return (
      <div
         className={classBadge}
         style={{
            color,
            background: `linear-gradient(90deg, ${backgroundColorLeft}, ${backgroundColorRight})`,
            padding,
         }}
      >
         {icon && <span className={styles.icon}>{icon}</span>}
         {children}
      </div>
   );
}

Badge.propTypes = {
   color: PropTypes.string,
   backgroundColorLeft: PropTypes.string,
   backgroundColorRight: PropTypes.string,
   icon: PropTypes.element,
   uppercase: PropTypes.bool,
   text: PropTypes.bool,
   className: stringWithoutSpaces,
};

Badge.defaultProps = {
   color: null,
   backgroundColorLeft: null,
   backgroundColorRight: null,
   icon: null,
   uppercase: false,
   text: false,
   className: null,
};

export default Badge;
