import React, { useEffect } from 'react';
import AsistantChat from 'AsistantChat';
import cx from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isBrowserUpdated from '../../services/isBrowserUpdated';
import Footer from './Footer';
import HeadMetaTags from './HeadMetaTags';
import Header from './Header';
import HeaderLite from './HeaderLite';
import styles from './layout.module.scss';

function Layout(props) {
   const {
      title,
      headerLite,
      children,
      simpleBar,
      className,
      index,
      disableShare,
      isErrorPage,
      landing,
      backgroundColor,
      description,
   } = props;
   const router = useRouter();
   const theme = useSelector((state) => state.theme.name);

   const classContainer = cx({
      [styles.cityStyle]: index && theme === 'city' && !backgroundColor,
   });
   useEffect(() => {
      // Toast de error en caso de ser un navegador desactualizado.
      isBrowserUpdated();
   }, []);
   return (
      <>
         {process.env.NEXT_PUBLIC_STORE_ID === 'city' && !router.pathname.includes('checkout') && (
            <AsistantChat />
         )}
         <HeadMetaTags title={title} disableShare={disableShare} description={description} />
         {headerLite ? (
            <HeaderLite />
         ) : (
            <Header
               simpleBar={simpleBar}
               isErrorPage={isErrorPage}
               index={index}
               landing={landing}
            />
         )}
         <section className={className || classContainer} style={{ backgroundColor }} role="main">
            {children}
         </section>

         {!headerLite && <Footer index={index} />}
      </>
   );
}

Layout.propTypes = {
   title: PropTypes.string,
   headerLite: PropTypes.bool,
   className: PropTypes.string,
   landing: PropTypes.bool,
   description: PropTypes.string,
};

Layout.defaultProps = {
   title: null,
   headerLite: false,
   className: null,
   landing: false,
   description: '',
};

export default Layout;
