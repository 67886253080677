import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import generateHrefLink from '../../../../../services/page/generateLinkHref';
import styles from './help.module.scss';

function Help() {
   const help = useSelector((state) => state.help);

   const renderFaqs = () => {
      return help.data.map((help) => (
         <li key={help.id}>
            <Link href={generateHrefLink(`ayuda/${help.slug}`, useRouter())}>
               <a>{help.title}</a>
            </Link>
         </li>
      ));
   };

   return (
      <>
         {help.length !== 0 && (
            <section className={styles.container}>
               <h4 className={styles.title}>Ayuda</h4>
               <ul className={styles.questions}>{renderFaqs()}</ul>
            </section>
         )}
      </>
   );
}

export default Help;
