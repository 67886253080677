import React, { useEffect, useState } from 'react';
import Input from 'Form/Input';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../../api';
import Button from '../../../../components/Button';
import useMetatagsData from '../../../../hooks/useMetatagsData';
import useTrackingQueue from '../../../../hooks/useTrackingQueue';
import { setNotificationError, setNotificationInfo } from '../../../../redux';
import createUUID from '../../../../services/createUUID';
import generateCodeSite from '../../../../services/page/generateCodeSide';
import sentry from '../../../../services/sentry';
import themes from '../../../../themes';
import styles from './newsletter.module.scss';

const haveImageInPosition = (position, newsletter) => {
   const image1 = newsletter.position_picture_1 === position && newsletter.show_picture_1;
   const image2 = newsletter.position_picture_2 === position && newsletter.show_picture_2;
   if (image1) return newsletter.picture_1_image?.url;
   if (image2) return newsletter.picture_2_image?.url;
   return null;
};

function Newsletter() {
   const router = useRouter();
   const dispatch = useDispatch();
   const { executeEvent } = useTrackingQueue();
   const [newsletter, setNewsletter] = useState({});
   const [, setEmail] = useState('');
   const [loading, setLoading] = useState(false);
   const [showMessage, setShowMessage] = useState(false);
   const newsletterApi = useSelector((state) => state.newsletter);
   const { STORE_ID, STORE_CAPTION_NEWSLETTER } = useMetatagsData();
   const theme = themes[STORE_ID];

   useEffect(() => {
      if (newsletterApi) {
         setNewsletter({
            ...newsletterApi,
            leftImage: haveImageInPosition('left', newsletterApi),
            rightImage: haveImageInPosition('right', newsletterApi),
         });
      } else {
         setNewsletter({
            button_text_color: theme['color-button-newsletter'],
            button_background_color: theme['background-button-newsletter'],
            background_color: theme['background-newsletter'],
            text_color: theme['caption-color-newsletter'],
            caption: STORE_CAPTION_NEWSLETTER,
         });
      }
   }, [newsletterApi]);
   const toggleMessage = () => {
      setShowMessage(true);

      setTimeout(() => {
         setShowMessage(false);
         setEmail('');
      }, 7000);
   };

   const handleSubmit = async (event) => {
      event.preventDefault();

      const emailInput = event.target.email.value;
      const eventId = createUUID();
      // Guardamos en Sentry que usuario esta logueado
      sentry.setUser({ email: emailInput });
      if (emailInput !== '') {
         executeEvent('subscribe', eventId);
         setLoading(true);
         const code = generateCodeSite(router);
         const response = await API.newsletter.post(emailInput, code, eventId);

         if (response.success) {
            dispatch(setNotificationInfo('Te suscribiste correctamente'));
            setEmail(emailInput);
            toggleMessage();
         } else {
            dispatch(setNotificationError(response.error));
         }

         setLoading(false);
      }
   };

   return (
      <div style={{ background: newsletter.background_color }}>
         <div className={styles.container}>
            {newsletter.background_image?.url && (
               <div>
                  <Image
                     src={newsletter.background_image.url}
                     layout="fill"
                     alt={newsletter.caption}
                     quality={100}
                  />
               </div>
            )}
            {newsletter.leftImage && (
               <div className={`${styles.image} ${styles.lf}`}>
                  <Image
                     src={newsletter.leftImage}
                     width={90}
                     height={90}
                     alt={newsletter.caption}
                     quality={100}
                  />
               </div>
            )}
            {newsletter.rightImage && (
               <div className={styles.imageMobile}>
                  <Image
                     src={newsletter.rightImage}
                     width={90}
                     height={90}
                     alt={newsletter.caption}
                     quality={100}
                  />
               </div>
            )}
            <div className={styles.newsletter}>
               <div className={styles.textNewsletter}>
                  <h3 translate="no" className={styles.title}>
                     NEWSLETTER
                  </h3>
               </div>
               <form onSubmit={handleSubmit} className={styles.containerForm}>
                  <p className={styles.subtitle}>{newsletter.caption}</p>
                  {showMessage && (
                     <div className={styles.containerTextAlert}>
                        <p>¡Te suscribiste con éxito!</p>
                     </div>
                  )}
                  <div className={styles.containerFormActions}>
                     <Input
                        id="ingresá_tu_mail_Newsletter"
                        register={() => {}}
                        name="email"
                        type="email"
                        autocomplete="email"
                        className={styles.input}
                        placeholder="Ingresá tu email"
                        required
                     />
                     <Button
                        id="suscribirme_Newsletter"
                        label="SUSCRIBIRME"
                        /* icon={<MdVerifiedUser />} */
                        className={styles.button}
                        loading={loading}
                        additionalStyles={{
                           color: newsletter.button_text_color,
                           backgroundColor: newsletter.button_background_color,
                        }}
                     />
                  </div>
               </form>
            </div>
            {newsletter.rightImage && (
               <div className={styles.image}>
                  <Image
                     src={newsletter.rightImage}
                     width={90}
                     height={90}
                     alt={newsletter.caption}
                     quality={100}
                  />
               </div>
            )}
         </div>
      </div>
   );
}

export default Newsletter;
