export default function verifyMicrositio(pathname) {
   // verificamos si es micrositio
   // ej: /city/xxxxxx/yyyyyyy/....
   const { micrositios, main } = JSON.parse(process.env.NEXT_PUBLIC_STORE_INFO || {})[
      process.env.NEXT_PUBLIC_STORE_ID
   ];
   let micrositioName = main.storeId;
   let isMicrositio = false;
   micrositios.forEach(({ storeId }) => {
      if (pathname.indexOf(storeId) !== -1) {
         micrositioName = storeId;
         isMicrositio = true;
      }
   });

   return { isMicrositio, micrositioName };
}
