import Image from 'next/image';
import styles from './poweredByVaypol.module.scss';

const ProweredByVaypol = () => {
   return (
      <div className={styles.powered}>
         <span>Powered By</span>
         <Image src="/assets/logos/vaypol.png" alt="VAYPOL" width={100} height={15} />
      </div>
   );
};
export default ProweredByVaypol;
