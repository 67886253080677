import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { MdKeyboardArrowDown } from 'react-icons/md';
import formatTextQuantity from '../../../../services/formatTextQuantity';
import styles from '../quantity.module.scss';

function ButtonAction(props) {
   const { onClick, quantity, open, countOnHand, hideCount } = props;

   const classIcon = cn({
      [styles.icon]: true,
      [styles['icon--active']]: open,
   });

   if (countOnHand === 1) {
      return <span className={styles.lastStock}>¡Último disponible!</span>;
   }

   if (quantity === 0) {
      return <span className={styles.text}>Selecciona un talle</span>;
   }

   return (
      <button type="button" className={styles.button} onClick={onClick}>
         <span className={styles.value}>{formatTextQuantity(quantity)}</span>

         {quantity > 0 && <MdKeyboardArrowDown className={classIcon} />}

         {countOnHand > 0 && !hideCount && (
            <span className={styles.count}>({countOnHand} disponibles)</span>
         )}
      </button>
   );
}

ButtonAction.propTypes = {
   onClick: PropTypes.func.isRequired,
   quantity: PropTypes.number.isRequired,
   open: PropTypes.bool.isRequired,
   countOnHand: PropTypes.number,
};

ButtonAction.defaultProps = {
   countOnHand: null,
};

export default ButtonAction;
