import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { MdArrowDropDown } from 'react-icons/md';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Dropdown from '../Dropdown';
import styles from './navbaritem.module.scss';

function NavBarItem(props) {
   const { item } = props;
   const ref = useRef(null);
   const [open, setOpen] = useState(false);

   useOutsideClick(ref, () => {
      if (open) {
         setOpen(false);
      }
   });

   const classContent = cn({
      [styles.content]: true,
      [styles['content--active']]: open,
   });

   const classIcon = cn({
      [styles.icon]: true,
      [styles['icon--active']]: open,
   });

   const handleClick = () => {
      setOpen(!open);
   };

   return (
      <li ref={ref} className={styles.wrapper}>
         <div className={classContent} onClick={handleClick} onKeyPress={handleClick} role="button">
            <span className={styles.label}>{item?.name}</span>
            <MdArrowDropDown className={classIcon} />
         </div>

         {open && <Dropdown {...item} selected={handleClick} />}
      </li>
   );
}

export default NavBarItem;
