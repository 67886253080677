import React from 'react';
import Link from 'next/link';
import { MdPhone } from 'react-icons/md';
import styles from './support.module.scss';

function CustomerSupport() {
   return (
      <div className={styles.containerSupport}>
         <Link href="tel:0810 666 0945">
            <a>
               <MdPhone size={50} className={styles.icon} />
            </a>
         </Link>

         <ul className={styles.support}>
            <li>
               <strong className={styles.title}>Centro de Atención</strong>
            </li>
            <li>
               <a href="tel: 0810 666 0945">
                  <strong className={styles.contact}>0810 666 0945</strong>
               </a>
            </li>
            <li>
               <span className={styles.attentionDays}>Lunes a Viernes 8:30 - 17:30</span>
            </li>
         </ul>
      </div>
   );
}

export default CustomerSupport;
