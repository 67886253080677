import React from 'react';
import SuggestionItem from './SuggestionItem';
import styles from './suggestions-list.module.scss';

export default function SuggestionsList(props) {
   const {
      data: { products, brands, sports },
      onClick,
   } = props;

   const renderSuggestion = (items) => {
      return items.map((x) => <SuggestionItem key={x.url} {...x} onClick={onClick} />);
   };

   if (products.length > 0 || brands.length > 0 || sports.length > 0) {
      return (
         <div className={styles.container}>
            {brands.length > 0 && (
               <>
                  <span className={styles.title}>Marcas</span>
                  {renderSuggestion(brands)}
               </>
            )}

            {sports.length > 0 && (
               <>
                  <span className={styles.title}>Deportes</span>
                  {renderSuggestion(sports)}
               </>
            )}

            {products.length > 0 && (
               <>
                  <span className={styles.title}>Productos</span>
                  {renderSuggestion(products)}
               </>
            )}
         </div>
      );
   }

   return null;
}
