export default function generateCodeSite(url) {
   const rootPath = url.pathname.split('/')[1];

   if (rootPath === 'city') {
      return 'city';
   }
   if (rootPath === 'vaypol') {
      return 'vaypol';
   }

   return process.env.NEXT_PUBLIC_STORE_ID;
}
