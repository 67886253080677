/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import { setNotificationError } from '../../redux';

const useSearchSuggestions = (inputRef) => {
   const dispatch = useDispatch();
   const storeId = useSelector((state) => state.theme.name);
   const [data, setData] = useState({ products: [], brands: [], sports: [] });
   const [awaitingInput, setAwaitingInput] = useState(false);

   useEffect(() => {
      const handleInputChange = () => {
         if (!awaitingInput) {
            setAwaitingInput(true);

            setTimeout(() => {
               if (!awaitingInput) {
                  setAwaitingInput(false);
                  getSuggestions();
               }
            }, 500);
         }
      };

      inputRef.current.addEventListener('input', handleInputChange);

      return () => {
         if (inputRef.current) {
            inputRef.current.removeEventListener('input', handleInputChange);
         }
      };
   }, [awaitingInput, inputRef]);

   const getSuggestions = async () => {
      if (inputRef.current) {
         const { value } = inputRef.current;

         if (value.length >= 3) {
            const result = await api.search.get(value, storeId);

            if (result.error) {
               dispatch(setNotificationError('No se pudo obtener las sugerencias'));
               return;
            }

            setData(result.data);
         }
      }
   };

   return { data };
};

export default useSearchSuggestions;
