import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import verifyMicrositio from '../../services/filters/verifyMicrositio';

export default function useMetatagsData() {
   const router = useRouter();
   const siteInformation = useSelector((state) => state.site);
   const { micrositios, main } = Object.keys(siteInformation).length
      ? siteInformation
      : JSON.parse(process.env.NEXT_PUBLIC_STORE_INFO || {})[process.env.NEXT_PUBLIC_STORE_ID];

   const { isMicrositio, micrositioName } = verifyMicrositio(router.pathname);

   const STORE_ID = isMicrositio ? micrositioName : process.env.NEXT_PUBLIC_STORE_ID;

   let micrositio;
   if (isMicrositio) micrositio = micrositios?.find(({ storeId }) => storeId === STORE_ID);
   const saveVariable = (prop) => (isMicrositio ? micrositio?.[prop] : main?.[prop]);

   const STORE_NAME = saveVariable('name');
   const STORE_DESCRIPTION = saveVariable('description');
   const STORE_KEYWORDS = saveVariable('keywords');
   const STORE_SLOGAN = saveVariable('slogan');
   const STORE_EMAIL = saveVariable('email');
   const STORE_PHONE = saveVariable('phone');
   const STORE_SOCIAL_NETWORKS = saveVariable('social_networks')?.split(',');
   const STORE_DOMAIN = saveVariable('domain');
   const STORE_CAPTION_NEWSLETTER = saveVariable('newsletterCaption');

   const STORE_ABOUT = `${STORE_DOMAIN}/ayuda/sobre-${STORE_ID}`;

   let STORE_LOGO;
   if (STORE_ID === 'vaypol') {
      STORE_LOGO =
         'https://s3.sa-east-1.amazonaws.com/www.vaypol.com.ar/app/public/assets/logo/logo-vaypol-va.png';
   } else {
      STORE_LOGO = `${STORE_DOMAIN}/assets/logos/${STORE_ID}.png`;
   }

   return {
      STORE_ID,
      STORE_NAME,
      STORE_LOGO,
      STORE_DESCRIPTION,
      STORE_KEYWORDS,
      STORE_SLOGAN,
      STORE_ABOUT,
      STORE_EMAIL,
      STORE_PHONE,
      STORE_SOCIAL_NETWORKS,
      STORE_DOMAIN,
      STORE_CAPTION_NEWSLETTER,
   };
}
