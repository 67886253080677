import React, { useRef } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import generateHrefLink from '../../../../services/page/generateLinkHref';
import styles from './navbarlink.module.scss';

function NavBarLink(props) {
   const { item } = props;
   const {
      name: label,
      url,
      className,
      background_color: backgroundColor,
      text_color: textColor,
   } = item;
   const router = useRouter();
   const ref = useRef(null);

   const classWrapper = cn({
      [styles.wrapper]: true,
      [className]: className,
   });

   const classLink = cn({
      [styles.link]: true,
   });

   return (
      <li ref={ref} className={classWrapper}>
         <Link href={generateHrefLink(url, router)}>
            <a className={classLink} style={{ color: textColor, backgroundColor }}>
               {label}
            </a>
         </Link>
      </li>
   );
}

export default NavBarLink;
