import { error } from '../toastify';

export default async function isBrowserUpdated() {
   if (false) {
      // TODO: Revisar las versiones funcionales
      const isBraveUpdated = (navigator.brave && (await navigator.brave.isBrave())) || false;
      const correctBrowsers = {
         Chrome: 89,
         Edge: 89,
         Firefox: 77,
         Brave: isBraveUpdated,
         Opera: 75,
         Safari: 11,
      };
      const getBrowserInfo = () => {
         const ua = navigator.userAgent;
         let tem;
         let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
         if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return `IE ${tem[1] || ''}`;
         }
         if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
         }
         M = M[2] ? [M[1], M[2]] : [window.navigator.userAgent, '-?'];
         tem = ua.match(/version\/(\d+)/i);
         if (tem != null) M.splice(1, 1, tem[1]);
         return M.join(' ');
      };
      const { 0: browser, 1: version } = getBrowserInfo().split(' ');
      if (!(version >= correctBrowsers[browser] || isBraveUpdated || !correctBrowsers[browser])) {
         error(
            'Ups.. Parece que usas una version desactualizada del navegador. Actualizalo para un mayor experiencia.',
         );
      }
   }
}
