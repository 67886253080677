/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useDeleteTagDuplicate from '../../../hooks/useDeleteTagDuplicate';
import useMetatagsData from '../../../hooks/useMetatagsData';
import verifyMicrositio from '../../../services/filters/verifyMicrositio';

const FACEBOOK_VERIFICATION_ID = process.env.NEXT_PUBLIC_FACEBOOK_VERIFICATION_ID;
const GOOGLE_VERIFICATION_ID = process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_ID;

export default function HeadMetaTags(props) {
   const { pathname, asPath } = useRouter();
   const { title, disableShare, description } = props;
   const {
      STORE_NAME,
      STORE_LOGO,
      STORE_DESCRIPTION,
      STORE_SLOGAN,
      STORE_EMAIL,
      STORE_ABOUT,
      STORE_PHONE,
      STORE_SOCIAL_NETWORKS,
      STORE_DOMAIN,
      STORE_KEYWORDS,
   } = useMetatagsData();
   const siteInformation = useSelector((state) => state.site);

   const CANONICAL_DOMAIN =
      typeof window !== 'undefined' ? window.location.origin : siteInformation.main?.domain;

   const { isMicrositio } = verifyMicrositio(pathname);
   const canonicalURL = CANONICAL_DOMAIN + asPath;

   useDeleteTagDuplicate('description', 0, 'meta');
   return (
      <Head>
         <title>
            {title
               ? `${
                    pathname.includes('[...slug]') // Galería
                       ? `${title} | Hasta 6 cuotas sin interés`
                       : `${title}${STORE_NAME ? ` | ${STORE_NAME}` : ''}`
                 }`
               : `${`${STORE_NAME} | ` || ''} ${STORE_SLOGAN || ''}`}
         </title>
         <meta name="description" content={description || STORE_DESCRIPTION} key="descriptionKey" />
         {STORE_KEYWORDS && <meta name="keywords" content={STORE_KEYWORDS} key="descriptionKey" />}
         <link rel="canonical" href={`${canonicalURL}`} />

         {/* Si es Micrositio Deshabilitamos los robots. */}
         {isMicrositio && <meta name="robots" content="noindex,nofollow" />}
         {!disableShare && (
            <>
               <meta property="og:type" content="website" />
               <meta property="og:url" content={STORE_DOMAIN} />
               <meta property="og:title" content={`${STORE_NAME} - ${STORE_SLOGAN}`} />
               <meta property="og:description" content={description || STORE_DESCRIPTION} />
               <meta property="og:image" content={STORE_LOGO} />

               <meta property="twitter:card" content="summary_large_image" />
               <meta property="twitter:url" content={STORE_DOMAIN} />
               <meta property="twitter:title" content={`${STORE_NAME} - ${STORE_SLOGAN}`} />
               <meta property="twitter:description" content={description || STORE_DESCRIPTION} />
               <meta property="twitter:image" content={STORE_LOGO} />
            </>
         )}

         {FACEBOOK_VERIFICATION_ID && (
            <meta name="facebook-domain-verification" content={FACEBOOK_VERIFICATION_ID} />
         )}
         {GOOGLE_VERIFICATION_ID && (
            <meta name="google-site-verification" content={GOOGLE_VERIFICATION_ID} />
         )}

         <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
               __html: JSON.stringify({
                  '@context': 'http://schema.org',
                  '@type': 'Organization',
                  name: STORE_NAME,
                  url: STORE_ABOUT,
                  email: STORE_EMAIL,
                  logo: STORE_LOGO,
                  '@id': STORE_DOMAIN,
                  telephone: STORE_PHONE,
                  sameAs: STORE_SOCIAL_NETWORKS,
               }),
            }}
         />
      </Head>
   );
}
