import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BiLogoYoutube, BiLogoTiktok } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import Brands from './Brands';
import Faq from './Faq';
import Help from './Help';
import CustomerSupport from './Support';
import styles from './footer-content.module.scss';

function FooterContent({ index }) {
   const isDesktop = useMediaQuery('(min-width: 1130px)');
   const site = useSelector((state) => state.site);
   const { socials } = useSelector((state) => state.help);
   const router = useRouter();
   const [evento, setEvento] = useState();

   let sitio = site.main;
   site?.micrositios.forEach((element) => {
      if (element.storeId === router.pathname?.split('/')[1]) sitio = element;
   });
   useEffect(() => {
      // eventos
      setEvento(sitio.event);
   }, [site, router.pathname]);

   return (
      <section className={styles.content}>
         <div className={styles.footerMain}>
            <div className={styles.containerCenter}>
               <div className={styles.containerQuestion}>
                  <div className={styles.questions}>
                     <Faq />
                  </div>
                  <div className={styles.questions}>
                     <Help />
                  </div>
                  {index && sitio.storeId === 'vaypol' && (
                     <div className={styles.questions}>
                        <Brands />
                     </div>
                  )}
               </div>
            </div>
            {index && isDesktop && evento?.status === 'true' && (
               <div className="naturalrace-logo">
                  <Link href={evento?.url}>
                     <a target="_blank" rel="noopener noreferrer">
                        <Image
                           src={`/assets/${evento?.name}-logo.svg`}
                           alt={`${evento?.name} logo`}
                           width="300"
                           height="80"
                           layout="fixed"
                        />
                     </a>
                  </Link>
               </div>
            )}
         </div>
         <div className={styles.support}>
            <CustomerSupport />
            {Object.keys(socials).length > 0 && (
               <div className={styles.containerNets}>
                  <span className={styles.spanSeguinos}>Seguinos</span>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                     {socials?.facebook && (
                        <Link href={socials?.facebook}>
                           <a target="_blank" rel="noopener noreferrer" className={styles.image}>
                              <Image
                                 src="/assets/facebook.svg"
                                 alt="Facebook"
                                 width={40}
                                 height={40}
                                 layout="fixed"
                                 loading="eager"
                              />
                           </a>
                        </Link>
                     )}
                     {socials?.instagram && (
                        <Link href={socials?.instagram}>
                           <a target="_blank" rel="noopener noreferrer" className={styles.image}>
                              <Image
                                 src="/assets/instagram.svg"
                                 alt="Instagram"
                                 width={40}
                                 height={40}
                                 layout="fixed"
                                 loading="eager"
                              />
                           </a>
                        </Link>
                     )}
                     {socials?.youtube && (
                        <Link href={socials?.youtube}>
                           <a target="_blank" rel="noopener noreferrer" className={styles.image}>
                              <div
                                 style={{
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    background: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                 }}
                              >
                                 <BiLogoYoutube color="black" size={30} />
                              </div>
                           </a>
                        </Link>
                     )}
                     {socials?.tiktok && (
                        <Link href={socials?.tiktok}>
                           <a target="_blank" rel="noopener noreferrer" className={styles.image}>
                              <div
                                 style={{
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    background: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                 }}
                              >
                                 <BiLogoTiktok color="black" size={30} />
                              </div>
                           </a>
                        </Link>
                     )}
                  </div>
               </div>
            )}
         </div>
         {index && !isDesktop && evento?.status === 'true' && (
            <div className="naturalrace-logo" style={{ marginTop: '3rem' }}>
               <Link href={evento?.url}>
                  <a target="_blank" rel="noopener noreferrer">
                     <Image
                        src={`/assets/${evento?.name}-logo.svg`}
                        alt={`${evento?.name} logo`}
                        width="300"
                        height="80"
                        layout="fixed"
                     />
                  </a>
               </Link>
            </div>
         )}
      </section>
   );
}

export default FooterContent;
