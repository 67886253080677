/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdAccountCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from '../../../redux';
import CartModal from '../../ProductDetail/CartModal';
import NavBar from '../NavBar';
import CartButton from './CartButton';
import SearchBar from './SearchBar';
import TopInfoBar from './TopInfoBar';
import UserItem from './UserItem';
import WrapperLogos from './WrapperLogos';
import styles from './header.module.scss';

const User = () => {
   const router = useRouter();
   const auth = useSelector((state) => state.auth);

   const handleClick = () => {
      localStorage.setItem('redirectTo', router.asPath);
   };

   return (
      <>
         {!auth.isLoggedIn && (
            <Link href="/login">
               <a className={styles.loginLink} onClick={handleClick}>
                  <MdAccountCircle size={22} />
                  Iniciar sesión
               </a>
            </Link>
         )}

         {auth.isLoggedIn && <UserItem />}
      </>
   );
};

function Header(props) {
   const { index, landing, isErrorPage, simpleBar } = props;
   const dispatch = useDispatch();
   const visible = useSelector((state) => state.cart.showModal);
   const cart = useSelector((state) => state.cart);

   const isMobile = useMediaQuery('(max-width: 768px)');
   useEffect(() => {
      if (isMobile) {
         document.getElementsByTagName('body')[0].classList.remove('show-modal');
      } else if (visible) {
         // cuando el modal se muestra, scrolleamos hacia arriba de todo
         window.scrollTo({ top: 0, behavior: 'smooth' });

         // le aplicamos la clase para que no se pueda hacer scroll (al div con id #__next)
         document.getElementsByTagName('body')[0].classList.add('show-modal');
      } else {
         // al cerrar quitamos la clase del body
         document.getElementsByTagName('body')[0].classList.remove('show-modal');
      }
      if (cart.exchangeCart) {
         dispatch(fetchCart());
      }
   }, [visible, isMobile]);

   return (
      <header className={styles.container}>
         <section className={styles.wrapperTop}>
            <div className={styles.wrapperTopSecond}>
               <TopInfoBar />
               <WrapperLogos isErrorPage={isErrorPage} />
            </div>
         </section>
         {!simpleBar && (
            <>
               <section className={styles.wrapperMedium}>
                  <div className={styles.content}>
                     <div className={styles.searchBar}>
                        <SearchBar />
                     </div>

                     <div className={styles.cartLogin}>
                        <User />
                        <CartButton />
                     </div>
                  </div>
               </section>
               <NavBar className={styles.navBar} index={index} landing={landing} />
               {visible && <CartModal />}
            </>
         )}
      </header>
   );
}

export default Header;
