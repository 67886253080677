/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import themes from '../../../../themes';
import styles from '../header.module.scss';

const Logo = (props) => {
   const { image, alt, storeId, theme, primary, backgroundColor, isErrorPage } = props;

   // backgroundColor = `backgroundColor: #0c0c0c`;

   const classNames = cn({
      [styles.logo]: true,
      [styles['logo--primary']]: primary,
      [styles['logo--active']]: theme.name === storeId,
   });

   const renderUrl = () => {
      if (process.env.NEXT_PUBLIC_STORE_ID === storeId) {
         return '/';
      }
      if (storeId === 'vaypol') {
         return 'https://www.vaypol.com.ar';
      }

      return `/${storeId}`;
   };

   const renderLogos = () => {
      if (storeId === 'acsm') {
         return (
            <Link href={renderUrl()}>
               <a className={classNames} style={{ backgroundColor }}>
                  <Image
                     src={image}
                     alt={alt}
                     width={320}
                     height={40}
                     layout="fixed"
                     priority
                     quality={100}
                     object-fit="cover"
                     object-position="center"
                  />
               </a>
            </Link>
         );
      }

      if (storeId === 'gye') {
         return (
            <Link href={renderUrl()}>
               <a className={classNames} style={{ backgroundColor: '#0c0c0c' }}>
                  <div style={{ position: 'relative', width: '50px' }}>
                     <Image
                        src={image}
                        alt={alt}
                        width={42}
                        height={40}
                        layout="responsive"
                        priority
                        className={styles.images}
                     />
                  </div>
               </a>
            </Link>
         );
      }
      if (!isErrorPage) {
         return (
            <Link href={renderUrl()}>
               <a
                  target={storeId === 'vaypol' ? '_blank' : '_self'}
                  className={classNames}
                  style={{ backgroundColor }}
               >
                  <Image src={image} alt={alt} width={160} height={40} layout="fixed" priority />
               </a>
            </Link>
         );
      }

      return (
         <a
            target={storeId === 'vaypol' ? '_blank' : '_self'}
            rel="noreferrer"
            href={renderUrl()}
            className={classNames}
            style={{ backgroundColor }}
         >
            <Image src={image} alt={alt} width={160} height={40} layout="fixed" priority />
         </a>
      );
   };

   return <>{renderLogos()}</>;
};

export default function WrapperLogos({ isErrorPage }) {
   const theme = useSelector((state) => state.theme);
   const site = useSelector((state) => state.site);
   const [isDesktop, setIsDesktop] = useState(null);

   const measure = useMediaQuery('(min-width:768px)');

   useEffect(() => {
      setIsDesktop(measure);
   }, [measure]);

   if (site.main.storeId !== 'vaypol' && site.main.storeId !== 'city') {
      return (
         <>
            {!isDesktop ? (
               <Link href="/">
                  <a
                     className={styles.newLogo}
                     style={{ backgroundColor: themes[site.main.storeId]['color-logo-bg'] }}
                  >
                     <Image
                        src={`/assets/logos/${site.main.logos.logo_header}`}
                        alt={`${site.main.name} | ${site.main.slogan}`}
                        width={384}
                        height={53}
                        layout="responsive"
                        priority
                        quality={100}
                        object-fit="cover"
                        object-position="center"
                     />
                  </a>
               </Link>
            ) : (
               <Link href="/" style={{ width: '100%' }}>
                  <a
                     className={styles.newLogo}
                     style={{ backgroundColor: themes[site.main.storeId]['color-logo-bg'] }}
                  >
                     <Image
                        src={`/assets/logos/${site.main.logos.logo_header}`}
                        alt={`${site.main.name} | ${site.main.slogan}`}
                        width={576} // 384 - 576
                        height={79.5} // 53 - 79.5
                        layout="fixed"
                        priority
                        quality={100}
                        object-fit="cover"
                        object-position="center"
                     />
                  </a>
               </Link>
            )}
         </>
      );
   }

   const renderLogos = () => {
      // if (site.main.storeId !== 'vaypol' && site.main.storeId !== 'city') {
      //    return (
      //       <>
      //          {!isDesktop ? (
      //             <Link href="/">
      //                <a
      //                   className={styles.logo}
      //                   style={{ backgroundColor: themes[site.main.storeId]['color-logo-bg'] }}
      //                >
      //                   <Image
      //                      src={`/assets/logos/${site.main.logos.logo_header}`}
      //                      alt={`${site.main.name} | ${site.main.slogan}`}
      //                      width={384}
      //                      height={53}
      //                      layout="responsive"
      //                      priority
      //                      quality={100}
      //                      object-fit="cover"
      //                      object-position="center"
      //                   />
      //                </a>
      //             </Link>
      //          ) : (
      //             <Link href="/" style={{ width: '100%' }}>
      //                <a
      //                   className={styles.logo}
      //                   style={{ backgroundColor: themes[site.main.storeId]['color-logo-bg'] }}
      //                >
      //                   <Image
      //                      src={`/assets/logos/${site.main.logos.logo_header}`}
      //                      alt={`${site.main.name} | ${site.main.slogan}`}
      //                      width={576} // 384 - 576
      //                      height={79.5} // 53 - 79.5
      //                      layout="fixed"
      //                      priority
      //                      quality={100}
      //                      object-fit="cover"
      //                      object-position="center"
      //                   />
      //                </a>
      //             </Link>
      //          )}
      //       </>
      //    );
      // }

      return (
         <>
            {site.main?.name && (
               <Logo
                  alt={`${site.main.name} | ${site.main.slogan}`} // "Vaypol | Casa de Deportes"
                  storeId={site.main.storeId} // "vaypol"
                  image={`/assets/logos/${site.main.logos.logo_header}`} // "/assets/logos/vaypol.svg"
                  theme={theme}
                  backgroundColor={themes[site.main.storeId]['color-logo-bg']}
                  isErrorPage={isErrorPage}
                  primary
               />
            )}
            {site.micrositios?.length > 0 &&
               site.micrositios?.map((s) => (
                  // buscar la URL externa del micrositio
                  <Logo
                     key={s.name}
                     alt={`${s.name} | ${s.slogan}`} // "Vaypol | Casa de Deportes"
                     storeId={s.storeId} // "vaypol"
                     image={`/assets/logos/${s.logos.logo_header}`} // "/assets/logos/vaypol.svg"
                     theme={theme}
                     backgroundColor={themes[s.storeId]['color-logo-bg']}
                     isErrorPage={isErrorPage}
                  />
               ))}
            {site.main?.name && (
               <div className={styles.fillBackground}>
                  <div
                     className={styles.logos}
                     style={{
                        backgroundColor: themes[site.main.storeId]['color-logo-bg'],
                     }}
                  />
                  <div className={styles.infoBar} />
               </div>
            )}
         </>
      );
   };
   return (
      <div className={site.micrositios?.length > 0 ? styles.wrapperLogo : styles.wrapperOneLogo}>
         {renderLogos()}
      </div>
   );
}
