import React from 'react';
import cn from 'classnames';
import styles from './placeholder.module.scss';

function Placeholder(props) {
   const { className, height } = props;

   const classContainer = cn({
      [styles.container]: true,
      [className]: !!className,
   });

   return <div className={classContainer} style={{ height }} />;
}

export default Placeholder;
