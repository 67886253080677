import React from 'react';
import Spinner from 'Spinner';
import styles from './loadingModal.module.scss';

const LoadingModal = ({ backgroundColor = 'rgb(0 0 0 / 70%)' }) => {
   return (
      <div className={styles.container} style={{ backgroundColor }}>
         <div className={styles.spinnerContainer}>
            <Spinner isLarge />
         </div>
      </div>
   );
};

export default LoadingModal;
