import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import generateHrefLink from '../../../../../services/page/generateLinkHref';
import styles from './faq.module.scss';

function Faq() {
   const faqs = useSelector((state) => state.faqs);

   const renderFaqs = () => {
      return faqs.map((faq) => (
         <li key={faq.id}>
            <Link href={generateHrefLink(`ayuda/${faq.slug}`, useRouter())}>
               <a>{faq.title}</a>
            </Link>
         </li>
      ));
   };

   return (
      <section className={styles.container}>
         <h4 className={styles.title}>Información</h4>
         <ul className={styles.questions}>
            {renderFaqs()}
            <li>
               <Link href={generateHrefLink('ayuda/sucursales', useRouter())}>
                  <a>Sucursales</a>
               </Link>
            </li>
            <li>
               <Link href="tel:0810 666 0945">
                  <a>Botón de arrepentimiento</a>
               </Link>
            </li>
         </ul>
      </section>
   );
}

export default Faq;
