import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import generateHrefLink from '../../../../services/page/generateLinkHref';
import styles from './image-link.module.scss';

function ImageLink(props) {
   const { url, image, name, selected } = props;

   return (
      <div className={styles.wrapper}>
         <Link href={generateHrefLink(url, useRouter())}>
            <a onClick={selected} onKeyPress={selected} role="link">
               <img className={styles.image} alt={`Ver todo: ${name}`} src={image.url} />
               <span className={styles.name}>Ver todo: {name}</span>
            </a>
         </Link>
      </div>
   );
}

ImageLink.propTypes = {
   name: PropTypes.string.isRequired,
   url: PropTypes.string.isRequired,
   image: PropTypes.shape({
      url: PropTypes.string,
      ext: PropTypes.string,
      blurhash: PropTypes.string,
   }).isRequired,
};

export default ImageLink;
