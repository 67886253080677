import React from 'react';
import CartItem from 'Cart/CartItem';
import { useSelector } from 'react-redux';
import styles from './cart-item-list.module.scss';

function CartItemList() {
   const cart = useSelector((state) => state.cart);

   const renderItems = () => {
      let noStock = false;

      const items = cart.line_items.map((item) => {
         if (item.count_on_hand === 0) {
            noStock = true;
         }
         return <CartItem key={item.id} {...item} />;
      });

      if (noStock) {
         items.unshift(
            <span className={styles.messageStock}>
               Un item (o más) de tu carro está sin stock, no lo verás cuando procedas a la compra
            </span>,
         );
      }

      return items;
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.table}>
            <div className={styles.header}>
               <span className={styles.item}>Producto</span>
               <span className={styles.quantity}>Cantidad</span>
               <span className={styles.price}>Precio</span>
               <span className={styles.remove}>Eliminar</span>
            </div>

            <div className={styles.body}>{renderItems()}</div>
         </div>
      </div>
   );
}

export default CartItemList;
