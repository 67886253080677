/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from '../../../hooks/useOutsideClick';
import ButtonAction from './ButtonAction';
import Dropdown from './Dropdown';
import styles from './quantity.module.scss';

function Quantity(props) {
   const { countOnHand, hideCount, onSelect } = props;

   const ref = useRef(null);
   const [value, setValue] = useState(props.value);
   const [open, setOpen] = useState(false);

   useEffect(() => {
      setValue(props.value);
   }, [props.value]);

   useOutsideClick(ref, () => {
      if (open) {
         setOpen(false);
      }
   });

   const handleClickButton = () => {
      setOpen(!open);
   };

   const handleSelectItem = (qty) => {
      onSelect(qty);
      setValue(qty);
      setOpen(false);
   };

   return (
      <div ref={ref} className={styles.wrapper}>
         <ButtonAction
            open={open}
            quantity={value}
            onClick={handleClickButton}
            countOnHand={countOnHand}
            hideCount={hideCount}
         />
         <div className={`${open ? styles.open : styles.noOpen}`}>
            <Dropdown countOnHand={countOnHand} quantity={value} onSelect={handleSelectItem} />
         </div>
      </div>
   );
}

Quantity.propTypes = {
   value: PropTypes.number.isRequired,
   countOnHand: PropTypes.number.isRequired,
   onSelect: PropTypes.func.isRequired,
};

export default Quantity;
