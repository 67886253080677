import React from 'react';
import { MdPhone } from 'react-icons/md';
import styles from './top-info-bar.module.scss';

export default function TopInfoBar() {
   return (
      <a href="tel:0810 666 0945" className={styles.container}>
         <span className={styles.text}>Centro de Atención al Cliente</span>
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <MdPhone />
            <span className={styles.bold}>0810 666 0945</span>
         </div>
      </a>
   );
}
