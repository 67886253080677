/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import LoadingModal from 'LoadingModal';
// import Spinner from 'Spinner';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Quantity from '../../../domain/ProductDetail/Quantity';
import { removeItemCart, setQuantityCart, hideModalCart } from '../../../redux';
import generateHrefLink from '../../../services/page/generateLinkHref';
import styles from './cart-item.module.scss';

function CartItem(props) {
   const {
      id,
      url,
      name,
      thumbnail,
      quantity,
      options_text,
      prices,
      count_on_hand,
      render_total,
      promo_description,
   } = props;
   const router = useRouter();
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);

   const handleSelectQuantity = async (qty) => {
      setLoading(true);
      await dispatch(setQuantityCart(id, qty));
      setLoading(false);
   };

   const handleClickRemove = async () => {
      setLoading(true);
      await dispatch(removeItemCart(id));
      setLoading(false);
   };
   const setIdColorCookie = () => {
      document.cookie = `idColor=${url.replace(/.*-(\d+)$/, '$1')}; max-age=${7200}; path=/`;
   };
   const hideCart = () => {
      setIdColorCookie();
      dispatch(hideModalCart());
   };

   const classPrices = cn({
      [styles.prices]: true,
      [styles['prices--no-stock']]: count_on_hand === 0,
   });

   return (
      <div className={styles.wrapper}>
         {loading && (
            // <div className={styles.loading}>
            //    <Spinner isLarge />
            // </div>
            <LoadingModal backgroundColor="rgb(0 0 0 / 50%)" />
         )}

         {/* Image */}
         <Link href={generateHrefLink(url.replace(/-(\d+)$/, ''), router)}>
            <a onContextMenu={setIdColorCookie}>
               <img src={thumbnail?.url} className={styles.image} alt={name} onClick={hideCart} />
            </a>
         </Link>

         <div className={styles.wrapperRight}>
            {/* Brand / Name / Color + Size */}
            <div className={styles.wrapperTop}>
               <div className={styles.nameColorSize}>
                  <Link href={generateHrefLink(url.replace(/-(\d+)$/, ''), router)}>
                     <a onContextMenu={setIdColorCookie}>
                        <div className={styles.name} onClick={hideCart}>
                           <h2>{name}</h2>
                        </div>
                     </a>
                  </Link>
                  <div className={styles.colorSize}>{options_text}</div>
               </div>
            </div>

            {!promo_description.promotion_name ? (
               <>
                  {/* Quantity */}
                  {count_on_hand > 0 && (
                     <div className={styles.quantity}>
                        <Quantity
                           value={quantity}
                           countOnHand={count_on_hand}
                           onSelect={handleSelectQuantity}
                           hideCount
                        />
                     </div>
                  )}
                  {/* Total */}
                  <div className={classPrices}>
                     {count_on_hand === 0 && (
                        <span className={styles.unavailable}>No disponible</span>
                     )}

                     {count_on_hand > 0 && (
                        <>
                           <span className={styles.current}>$ {render_total}</span>

                           {prices.sale_price && (
                              <strike className={styles.original}>$ {prices.original}</strike>
                           )}
                        </>
                     )}
                  </div>

                  <button className={styles.remove} onClick={handleClickRemove}>
                     <MdDelete size={20} /> Eliminar
                  </button>
               </>
            ) : (
               <>
                  <span className={classPrices}>{promo_description.promotion_name}</span>
                  {promo_description.promotion_name && (
                     <>
                        {/* Total */}
                        <div className={classPrices}>
                           {/* {count_on_hand === 0 && <span className={styles.unavailable}>No disponible</span>} */}

                           {count_on_hand > 0 && (
                              <>
                                 <span className={styles.current}>$ {render_total}</span>
                                 <strike className={styles.original}>$ {prices.original}</strike>
                              </>
                           )}
                        </div>
                        <button className={styles.remove} onClick={handleClickRemove}>
                           <MdDelete size={20} /> Eliminar
                        </button>
                     </>
                  )}
               </>
            )}
         </div>
      </div>
   );
}

export default CartItem;
