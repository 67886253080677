import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import generateHrefLink from '../../../../../services/page/generateLinkHref';
import styles from './brands.module.scss';

function Brands() {
   const brands = useMemo(
      () => [
         { id: 1, name: 'New Balance', url: 'new-balance' },
         { id: 2, name: 'Kappa', url: 'kappa' },
         { id: 3, name: 'Salomon', url: 'salomon' },
         { id: 4, name: 'Asics', url: 'asics' },
         { id: 5, name: 'DRB', url: 'drb' },
      ],
      [],
   );

   const renderBrands = () => {
      return brands.map((brand) => (
         <li key={brand.id}>
            <Link href={generateHrefLink(`productos/p/1?marca=${brand.url}`, useRouter())}>
               <a>{brand.name}</a>
            </Link>
         </li>
      ));
   };

   return (
      <section className={styles.container}>
         <h4 className={styles.title}>Marcas populares</h4>
         <ul className={styles.brands}>{renderBrands()}</ul>
      </section>
   );
}

export default Brands;
