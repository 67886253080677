import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Button from 'Button';
import router from 'next/router';
import { MdShoppingCart } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { showModalCart } from '../../../../redux';
import styles from './cart-button.module.scss';

function CartButton() {
   const disptach = useDispatch();
   const cart = useSelector((state) => state.cart);
   const isMobile = useMediaQuery('(max-width: 768px)');

   const handleClick = () => {
      if (isMobile || cart.item_count === 0) {
         router.push('/cart');
      } else {
         disptach(showModalCart());
      }
   };

   return (
      <Button
         label="Carrito"
         icon={<MdShoppingCart />}
         iconBadge={cart.item_count}
         className={styles.button}
         onClick={handleClick}
         outlined
      />
   );
}

export default CartButton;
