import React, { useEffect, useState } from 'react';

// este componente ejecuta una animacion de fadeOut
// y deja de renderizarse cuando show es false
const Fade = ({ show, className, children }) => {
   const [render, setRender] = useState(show);

   useEffect(() => {
      if (show) setRender(true);
   }, [show]);

   const onAnimationEnd = () => {
      if (!show) setRender(false);
   };

   return render ? (
      <div
         style={!show ? { animation: 'fadeOut .25s' } : { animation: 'fadeIn .25s' }}
         onAnimationEnd={onAnimationEnd}
         className={className}
      >
         {children}
      </div>
   ) : null;
};

export default Fade;
