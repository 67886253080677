/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useRef } from 'react';
import router from 'next/router';
import { MdSearch } from 'react-icons/md';
import { useSelector } from 'react-redux';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import useSearchSuggestions from '../../../../hooks/useSearchSuggestions';
import useTrackingQueue from '../../../../hooks/useTrackingQueue';
import generateHrefLink from '../../../../services/page/generateLinkHref';
import verifyLanding from '../../../../services/verifyLanding';
import SuggestionsList from './SuggestionsList';
import styles from './searchbar.module.scss';

export default function SearchBar() {
   const inputRef = useRef(null);
   const formRef = useRef(null);
   const storeId = useSelector((state) => state.theme.name);
   const { executeEvent } = useTrackingQueue();
   const { data } = useSearchSuggestions(inputRef);
   const [showSuggestions, setShowSuggestions] = useState(false);

   useOutsideClick(formRef, () => {
      setShowSuggestions(false);
   });

   const handleClickInput = () => {
      if (!showSuggestions) {
         setShowSuggestions(true);
      }
   };

   const validateSuggestionsInput = () =>
      inputRef.current.value.length === 0 && setShowSuggestions(false);

   const handleSuggestions = () => {
      if (!showSuggestions) setShowSuggestions(true);
      validateSuggestionsInput();
   };

   const handleChangeInput = () => {
      handleSuggestions();
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      const { value } = e.target.search;

      if (value !== '') {
         setShowSuggestions(false);

         // ejecutamos el evento "search" de analytics
         executeEvent('search');

         // verificamos si es una landing y lo redireccionamos al link ya formateado
         // si no es landing, entonces es una busqueda comun
         let url;
         const isLanding = await verifyLanding(encodeURIComponent(value), storeId);

         if (isLanding) {
            url = generateHrefLink(isLanding.value, router);
         } else {
            url = generateHrefLink(
               `productos/k/${value.replace(/ /g, '-').toLowerCase()}/p/1`,
               router,
            );
         }

         router.push(url);
      }
   };

   return (
      <form ref={formRef} className={styles.container} onSubmit={handleSubmit}>
         <div className={styles.wrapperInput}>
            <MdSearch />
            <input
               ref={inputRef}
               type="text"
               name="search"
               placeholder="Buscá por productos, marcas y más..."
               onClick={handleClickInput}
               onChange={handleChangeInput}
               autoComplete="off"
               aria-label="Buscar"
            />
            {/* <button type="submit">Buscar</button> */}
         </div>

         {showSuggestions && (
            <SuggestionsList data={data} onClick={() => setShowSuggestions(false)} />
         )}
      </form>
   );
}
